import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "check-boxes"
    }}>{`Check Boxes`}</h1>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Keep all check box text concise.`}</li>
        <li parentName="ul">{`List options in a logical order, such as alphabetically or in order by most likely to be selected
first.`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Don't use periods at the end of check box text.`}</li>
      </ul>
    </Dont>
    <h2 {...{
      "id": "capitalization"
    }}>{`Capitalization`}</h2>
    <p>{`Check box labels may be capitalized in 2 ways, depending on their length and placement in the UI:`}</p>
    <ul>
      <li parentName="ul">{`Use title case for short labels that are to the left or above a check box.`}</li>
      <li parentName="ul">{`Use sentence case for longer labels that are placed to the right of a check box, especially if the
label reads like a phrase.`}</li>
    </ul>
    <p>{`We encourage the use of shorter, more concise labels whenever possible.`}</p>
    <p>{`See also: `}<a parentName="p" {...{
        "href": "/guidelines/content/ui-text/radio-buttons"
      }}>{`Radio Buttons`}</a>{`,
`}<a parentName="p" {...{
        "href": "/guidelines/content/ui-text/dropdown-menus"
      }}>{`Drop-down Menus`}</a>{`, and
`}<a parentName="p" {...{
        "href": "/guidelines/content/ui-text/instructional-text"
      }}>{`Instructional Text`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      